import { instance } from '@smf/ui-main-container-app';

const urlEndpoints = {
  hierarchy: '/plantOverview/hierarchy',
  digitalTwinHierarchy: 'digitalTwin/digitalTwinHierarchy',
  digitalTwinConfig: 'digitalTwin/twinMakerConfig',
  configStatus: '/factorygeneral/status',
};

export async function getHierarchy(entityId) {
  const params = {
    entityId: entityId,
  };
  const result = await instance.get(urlEndpoints.hierarchy, {
    params,
  });
  return result.data;
}

export async function getDigitalTwinConfig(entityId) {
  const params = {
    entityId,
  };
  const result = await instance.get(urlEndpoints.digitalTwinConfig, {
    params,
  });
  return result.data;
}

export async function getDigitalTwinHierarchy(entityId, isMaxAvailability) {
  const params = {
    entityId,
    isMaxAvailability,
  };
  const result = await instance.get(urlEndpoints.digitalTwinHierarchy, {
    params,
  });
  return result.data;
}
