import { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { SmfToggle } from '@smf/ui-styleguide';
import NavigationBreadcrumb from '../NavigationBreadcrumb';
import DigitalTwinPageTitle from '../DigitalTwinPageTitle';
import DigitalTwinDetails from '../DigitalTwinDetails';
import {
  DigitalTwinContainer,
  DigitalTwinContent,
  DigitalTwinSceneContainer,
  DigitalTwinTitleAndDetailsContainer,
  DigitalTwinTitleDetailsToggleContainer,
  ToggleContainer,
} from '../../utils/styles';
import { useRxjsState, updateRxjsState } from '../../hooks/useRxjsState';
import DigiTwin from './scene';
import { DATA_TEST_IDS, queryNames } from '../../constants';
import { getHierarchy } from '../../utils/apiHelper';

const DigitalTwin = () => {
  const { rxjsState } = useRxjsState();
  const factoryId = rxjsState?.factoryId;
  const isMaxAvailabilityToggleEnabled = factoryId
    ? rxjsState?.plantMaxAvailabilityMeta[factoryId]
    : false;
  const [plantTimeZone, setPlantTimeZone] = useState('');
  const [plantLocation, setPlantLocation] = useState('');
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const setMaxAvailabilityToggle = (flag) => {
    const updatedPlantMaxAvailabilityMeta = {
      ...rxjsState.plantMaxAvailabilityMeta,
      [factoryId]: flag,
    };
    updateRxjsState({
      plantMaxAvailabilityMeta: updatedPlantMaxAvailabilityMeta,
    });
  };

  const { data: hierarchyData } = useQuery({
    queryKey: [queryNames.GET_HIERARACHY],
    queryFn: async () => {
      const data = await getHierarchy(factoryId);
      setPlantTimeZone(data?.hierarchyData?.plantTimeZone);
      setPlantLocation(data?.hierarchyData?.plantLocation);
      return data?.hierarchyData;
    },
    enabled: !!factoryId,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  return (
    <DigitalTwinContainer
      data-testid={DATA_TEST_IDS.DIGITAL_TWIN_CONTAINER}
      isMobileView={isMobileView}
    >
      <DigitalTwinContent>
        <NavigationBreadcrumb />
        <DigitalTwinTitleDetailsToggleContainer isMobileView={isMobileView}>
          <DigitalTwinTitleAndDetailsContainer>
            <DigitalTwinPageTitle isMobileView={isMobileView} />
            <DigitalTwinDetails
              plantLocation={plantLocation}
              plantTimeZone={plantTimeZone}
            />
          </DigitalTwinTitleAndDetailsContainer>
          <ToggleContainer isMobileView={isMobileView}>
            <SmfToggle
              isSwitched={isMaxAvailabilityToggleEnabled}
              setIsSwitched={setMaxAvailabilityToggle}
              isDark={rxjsState.isDark}
            />
          </ToggleContainer>
        </DigitalTwinTitleDetailsToggleContainer>
        <DigitalTwinSceneContainer>
          <DigiTwin hierarchyData={hierarchyData} />
        </DigitalTwinSceneContainer>
      </DigitalTwinContent>
    </DigitalTwinContainer>
  );
};

export default DigitalTwin;
